

















































































































































































































































// types
import { SelectedProduct, OrderPreviewInfo } from "@/components/Product/Types";

// library
import cloneDeep from "lodash/cloneDeep";
import Lottie from "vue-lottie";

// components
import OrderPreview from "@/components/Common/OrderPreview.vue";
import { Checkbox } from "@/components/Common/v2/Checkbox";
import DeleteModal from "@/components/Cart/DeleteModal.vue";
import PlusIcon from "@/assets/ElementsImage/PlusIcon.vue";
import MinusIcon from "@/assets/ElementsImage/MinusIcon.vue";
import EmptyBagIcon from "@/assets/ElementsImage/EmptyBagIcon.vue";
import spinningCircleData from "@/assets/Lottie/SpinningCircle.json";

// hooks
import formatNumber from "@/components/Hooks/formatNumber";
import { syncProductsInCart } from "@/api/cart";

import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  onBeforeUnmount,
  reactive,
} from "@vue/composition-api";

export default defineComponent({
  components: {
    OrderPreview,
    Checkbox,
    DeleteModal,
    PlusIcon,
    MinusIcon,
    EmptyBagIcon,
    Lottie,
  },
  props: {
    cartItems: { type: Array as () => SelectedProduct[], required: true },
    shopIdx: { type: Number, required: true },
  },
  emits: ["changeQty", "deleteItems"],
  setup(props, context) {
    const store = context.root.$store;

    const makeSelectedOptions = (item: SelectedProduct) => {
      const baseOptions = item.options;
      // console.log(baseOptions);
      const additionalOptions: any[] = [];
      const combineOptions = baseOptions.concat(additionalOptions);

      const selectedOptions: string[] = [];

      combineOptions.forEach((option) => {
        if (option.type === "CHOICE") {
          const choice = option.addPrice
            ? `${option.name}(+${formatNumber(option.addPrice)})`
            : option.name;
          selectedOptions.push(choice);
        } else {
          const text = option.addPrice
            ? `${option.text}(+${formatNumber(option.addPrice)})`
            : option.text;
          if (text) selectedOptions.push(text);
        }
      });

      return selectedOptions;
    };

    const makeOrderPreviewInfo = (item: SelectedProduct) => {
      const previewInfo: OrderPreviewInfo = {
        productName: item.productName,
        price: item.price,
        thumbnailImg: item.thumbnailImg,
        marketPrice: item.marketPrice,
        quantity: item.qty,
        selectedOptions: makeSelectedOptions(item),
      };
      return previewInfo;
    };

    const itemStateInfo = ref<{ state: string; isCheck: boolean }[]>([]);

    const isReadyToShowItems = computed(
      () => itemStateInfo.value.length > 0 && props.cartItems.length > 0
    );
    const isAllItemCheck = ref(true);

    const checkAll = () => {
      if (isAllItemCheck.value) {
        for (const key in itemStateInfo.value) {
          itemStateInfo.value[Number(key)].isCheck = false;
        }
      } else {
        for (const key in itemStateInfo.value) {
          if (itemStateInfo.value[Number(key)].state === "VALID") {
            itemStateInfo.value[Number(key)].isCheck = true;
          }
        }
      }

      isAllItemCheck.value = !isAllItemCheck.value;
    };

    const onChangeIndividualProduct = (index: number) => {
      if (itemStateInfo.value[index] == null) {
        return;
      }
      itemStateInfo.value[index].isCheck = !itemStateInfo.value[index].isCheck;

      const hasNotChecked = Object.values(itemStateInfo.value).some(
        ({ state, isCheck }) => {
          return state === "VALID" && !isCheck;
        }
      );

      isAllItemCheck.value = !hasNotChecked;
    };

    const checkedItems = computed(() => {
      const itemList: SelectedProduct[] = [];
      for (const key in itemStateInfo.value) {
        if (itemStateInfo.value[Number(key)].isCheck) {
          itemList.push(props.cartItems[Number(key)]);
        }
      }
      return itemList;
    });

    const checkedIndices = computed(() => {
      const indices: number[] = [];
      for (const key in itemStateInfo.value) {
        if (itemStateInfo.value[Number(key)].isCheck) {
          indices.push(Number(key));
        }
      }

      return indices;
    });

    const checkCount = computed(() => checkedItems.value.length);

    const calcSelectedProductPricePerQty = (product: SelectedProduct) => {
      const baseOptionPriceSum = product.options.reduce((acc, option) => {
        return acc + option.addPrice;
      }, 0);

      const additionalOptionPriceSum = 0;

      const sum =
        (product.price + baseOptionPriceSum + additionalOptionPriceSum) *
        product.qty;

      return sum;
    };

    const cartTotal = computed(() => {
      let total = 0;
      // 체크 안했을 시 0
      if (checkCount.value === 0) {
        return total;
      }
      // 체크 했을 시 체크한 상품 가격 합
      else {
        props.cartItems.forEach((item, index) => {
          if (!itemStateInfo.value) return;
          if (itemStateInfo.value[index].isCheck) {
            total += calcSelectedProductPricePerQty(item);
          }
        });
      }
      return formatNumber(total);
    });

    const isInitialized = ref(false);

    const loadingAnimationIcon = ref();

    const loadingAnimationOptions = reactive({
      animationData: spinningCircleData,
    });

    const handleLoadingAnimation = (anim: any) => {
      loadingAnimationIcon.value = anim;
    };

    const initItemStateInfo = async () => {
      if (props.cartItems.length === 0) {
        isInitialized.value = true;
        return;
      }

      try {
        const latestCartProducts = await syncProductsInCart(props.cartItems);
        if (!latestCartProducts) return;

        let stateInfo: { state: string; isCheck: boolean }[] = [];
        props.cartItems.forEach((item, index) => {
          stateInfo = [
            ...stateInfo,
            {
              state: latestCartProducts[index].state,
              isCheck:
                latestCartProducts[index].state !== "VALID"
                  ? false
                  : itemStateInfo.value[index]?.isCheck ?? true,
            },
          ];
        });
        itemStateInfo.value = stateInfo;
      } catch {
        itemStateInfo.value = props.cartItems.map(() => ({
          isCheck: false,
          state: "INVALID",
        }));
      } finally {
        isInitialized.value = true;
      }
    };

    const changeQuantity = async (state: string, index: number) => {
      const targetItem = props.cartItems[index];

      switch (state) {
        case "minus":
          if (targetItem.qty - 1 < 1) return;
          context.emit("changeQty", { index, qty: targetItem.qty - 1 });
          initItemStateInfo();

          break;
        case "plus":
          context.emit("changeQty", { index, qty: targetItem.qty + 1 });
          initItemStateInfo();
          break;
      }
    };

    const deleteWatingList = ref<number[]>([]);

    const checkDeleteItems = (deleteIdxList: number[]) => {
      deleteWatingList.value = deleteIdxList;
    };

    const deleteCheckedItems = () => {
      const deleteIdxList: number[] = [];
      for (const key in itemStateInfo.value) {
        if (itemStateInfo.value[Number(key)].isCheck) {
          deleteIdxList.unshift(Number(key));
        }
      }
      deleteWatingList.value = deleteIdxList;
    };

    const deleteItems = () => {
      context.emit("deleteItems", deleteWatingList.value);
      deleteWatingList.value.sort((a, b) => b - a);
      deleteWatingList.value.forEach((index) => {
        itemStateInfo.value.splice(index, 1);
      });
      deleteWatingList.value = [];
    };

    const moveToPayPage = async () => {
      // 체크 안했을 시
      if (checkCount.value === 0) {
        alert("선택된 상품이 없습니다.");
        return;
      }

      try {
        const prevStateInfo = cloneDeep(itemStateInfo.value);

        await initItemStateInfo();

        // 주문 가능 여부 확인
        const hasImpossibleProduct = prevStateInfo.some(
          (prevState: any, index: number) => {
            const { isCheck } = prevState;
            const { state } = itemStateInfo.value[index];
            if (isCheck && state !== "VALID") {
              return true;
            } else {
              return false;
            }
          }
        );

        if (hasImpossibleProduct) {
          alert("주문 불가능한 상품이 있습니다.");
          return;
        }

        const orderProducts: { [shopIdx: number]: SelectedProduct[] } = {};
        orderProducts[props.shopIdx] = checkedItems.value;
        const selectedCartIndicesObj: { [shopIdx: number]: number[] } = {};
        selectedCartIndicesObj[props.shopIdx] = checkedIndices.value;

        store.commit("setOrderProducts", orderProducts);
        store.commit("setSelectedCartIndices", selectedCartIndicesObj);

        context.root.$router.push({
          name: "OrderPage",
        });
      } catch {
        alert("주문 가능 상품 확인을 완료하지 못 했습니다.");
      }
    };

    onMounted(async () => {
      await initItemStateInfo();
    });

    watch(
      () => deleteWatingList.value.length,
      () => {
        if (deleteWatingList.value.length > 0) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      }
    );

    onBeforeUnmount(() => {
      document.body.classList.remove("modal-open");
    });

    return {
      isReadyToShowItems,
      itemStateInfo,
      isAllItemCheck,
      checkCount,
      cartTotal,
      deleteWatingList,
      checkedIndices,
      isInitialized,
      loadingAnimationOptions,
      handleLoadingAnimation,
      formatNumber,
      makeOrderPreviewInfo,
      checkAll,
      changeQuantity,
      checkDeleteItems,
      deleteCheckedItems,
      deleteItems,
      moveToPayPage,
      calcSelectedProductPricePerQty,
      onChangeIndividualProduct,
    };
  },
});
