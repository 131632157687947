















// types
import { Shop } from "@/components/Shop/Types";
import { SelectedProduct } from "@/components/Product/Types";

// components
import BaseLayout from "@/components/Layout/BaseLayout.vue";
import MyshopInfoLayout from "@/components/Layout/MyshopInfoLayout.vue";
import SmartPhoneLayout from "@/components/Layout/SmartPhoneLayout.vue";
import ShopInfoMain from "@/components/Shop/ShopInfoMain.vue";
import CartItems from "@/components/Cart/CartItems.vue";
import ChannelTalk from "@/components/ChannelTalk/ChannelTalk.vue";
import BaseHeader from "@/components/Layout/BaseHeader.vue";

// hooks
import getLocalStorageCart from "@/components/Hooks/getLocalStorageCart";

import { defineComponent, computed } from "@vue/composition-api";
export default defineComponent({
  components: {
    BaseLayout,
    MyshopInfoLayout,
    SmartPhoneLayout,
    ShopInfoMain,
    CartItems,
    ChannelTalk,
    BaseHeader,
  },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
  },
  setup(props, context) {
    const store = context.root.$store;

    const cartItems = computed<SelectedProduct[] | null>(() => {
      if (props.shopInfo && store.state.cartItems) {
        const items = store.state.cartItems[props.shopInfo.idx];
        return items ? items : [];
      } else {
        return null;
      }
    });

    const saveCartItems = () => {
      if (!props.shopInfo) return;
      const shopIdx = props.shopInfo.idx;
      const currentData = getLocalStorageCart();
      if (currentData) currentData[shopIdx] = cartItems.value;

      localStorage.setItem("cart", JSON.stringify(currentData));
    };

    const changeQty = async ({
      index,
      qty,
    }: {
      index: number;
      qty: number;
    }) => {
      if (!cartItems.value) return;
      const target = cartItems.value[index];
      if (!target) return;

      // 수량 변경(로컬 스토리지 / store)
      target.qty = qty;
      saveCartItems();
    };

    const deleteItems = (idxList: number[]) => {
      // 타겟 아이템 삭제(vue)
      idxList.forEach((idx) => {
        if (!cartItems.value) return;
        cartItems.value.splice(idx, 1);
      });

      // 타겟 아이템 삭제 -> 저장(로컬 스토리지)
      saveCartItems();
    };

    return {
      cartItems,

      changeQty,
      deleteItems,
    };
  },
});
