








































// types
import { SelectedProduct } from "@/components/Product/Types";

// components
import Modal from "@/components/Common/Modal.vue";

import { defineComponent, computed } from "@vue/composition-api";
export default defineComponent({
  components: {
    Modal,
  },
  props: {
    cartItems: { type: Array as () => SelectedProduct[], required: true },
    deleteWatingList: { type: Array as () => number[], required: true },
  },
  setup(props, context) {
    const isSingleItem = computed(() => props.deleteWatingList.length === 1);
    const firstItem = computed(
      () => props.cartItems[props.deleteWatingList[0]]
    );
    return { isSingleItem, firstItem };
  },
});
