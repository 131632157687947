







































































import { defineComponent, ref, computed } from "@vue/composition-api";

import checkDarkTheme from "@/components/Shop/Hooks/checkDarkTheme";

import { Shop } from "@/components/Shop/Types";

import InstagramLogo from "@/assets/Logo/InstagramGradientBorderLogo.vue";
import BlogLogo from "@/assets/Logo/BlogLogoSquare.vue";
import ProfileIcon from "@/assets/ElementsImage/ProfileIcon2.vue";
import YoutubeLogo from "@/assets/Logo/YoutubeLogo.vue";

export default defineComponent({
  components: { InstagramLogo, BlogLogo, ProfileIcon, YoutubeLogo },
  props: {
    shopInfo: {
      type: Object as () => Shop,
      required: true,
    },
  },
  setup(props) {
    const introduction = !props.shopInfo.introText
      ? `안녕하세요. ${props.shopInfo.name}입니다.`
      : props.shopInfo.introText;

    const isProfileImgBroken = ref(false);

    const homePage = computed(() => `/id/${props.shopInfo.urlPath}`);

    const isDarkTheme = computed(() => {
      if (!props.shopInfo) return false;
      const isDark = checkDarkTheme(props.shopInfo.color);

      return isDark;
    });

    return { introduction, isProfileImgBroken, homePage, isDarkTheme };
  },
});
