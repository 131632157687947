import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

import { SelectedProduct } from "@/components/Product/Types";

const api = axios.create({ baseURL: process.env.VUE_APP_BACKEND_SERVER });

const toCartProductsRequestData = (cartProducts: SelectedProduct[]) => {
  const products = cartProducts.map((product) => {
    const { options, optionType, productNo, qty } = product;
    const nomalizedBaseOptions: { optionIdx: { optionIdx: number }[] } = {
      optionIdx: [],
    };
    const normalizeCombOptions = (options: SelectedProduct["options"]) => {
      if (options.length === 0) {
        return [];
      } else {
        return [{ optionIdx: options[options.length - 1].optionIdx }];
      }
    };

    const normalizeSingleOptions = (options: SelectedProduct["options"]) => {
      return options.map(({ optionIdx }) => ({ optionIdx }));
    };

    nomalizedBaseOptions.optionIdx =
      optionType === "SINGLE"
        ? normalizeSingleOptions(options)
        : normalizeCombOptions(options);

    const normalizedAdditionalOptions: any[] = [];

    return {
      productNo,
      qty,
      option: {
        ...nomalizedBaseOptions,
        additionalOptions: normalizedAdditionalOptions,
      },
    };
  });

  return snakecaseKeys(products, { deep: true });
};

export const syncProductsInCart = async (
  originalCartProducts: SelectedProduct[]
) => {
  const _productList = toCartProductsRequestData(originalCartProducts);
  const res = await api.post("/cart/v2/list/shp/", {
    product_list: _productList,
  });

  const data = camelcaseKeys(res.data.data, { deep: true }); // state: NOT_ENOUGH | NOT_EXIST | VALID | INVALID | EXPIRED

  return data;
};
